.marketing-email-container {

}

    .marketing-email-container .form-title {
        text-align: center;
        margin-bottom: 3%;
    }

    .marketing-email-container .selection-row {
        width: 100%;
    }

        .marketing-email-container .selection-row select {
            width: 25%;
        }

        .marketing-email-container .selection-row button {
            margin-left: 2%;
        }

        .marketing-email-container .selection-row .right-part {
            float: right;
        }

    .marketing-email-container .form-box {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-row-gap: 10px;
        grid-column-gap: 10px;
        margin-top: 2%;
        margin-bottom: 2%;
    }

        .marketing-email-container .form-box .marketing-email__1 {
            
        }

        .marketing-email-container .form-box .marketing-email__2 {
            grid-column: span 2;
        }

        .marketing-email-container .form-box .marketing-email__3 {
            grid-column: span 3;
        }

            .marketing-email-container .form-box .marketing-email__3.input-row {
                margin-top: 5px;
                display: grid;
                grid-template-columns: 1fr 5fr;
                grid-row-gap: 10px;
                grid-column-gap: 10px;
            }

                .marketing-email-container .form-box .marketing-email__3.input-row label {
                    font-weight: bold;
                }

                .marketing-email-container .form-box .marketing-email__3.input-row input {
                    
                }

        .marketing-email-container .form-box .center-column {
            text-align: center;
        }
