.send-marketing-email-container {

}

    .send-marketing-email-container .form-title {
        text-align: center;
        margin-bottom: 3%;
    }

    .send-marketing-email-container .task-row {

    }

        .send-marketing-email-container .task-row button {
            margin-left: 2%;
        }

    .send-marketing-email-container .filter-row {

    }

        .send-marketing-email-container .filter-row label {
            font-weight: bold;
        }

        .send-marketing-email-container .filter-row input,
        .send-marketing-email-container .filter-row select {
            width: 25%;
            margin-left: 2%;
        }
