.donations-container .ag-header-icon.ag-header-cell-menu-button {
    opacity: 1 !important;
}

.donations-container .ag-header-cell-filtered .ag-header-icon.ag-header-cell-menu-button,
.donations-container .ag-header-cell-filtered .ag-header-icon.ag-header-label-icon.ag-filter-icon {
    color: red;
}

.client-selection-container {
    text-align: center;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    grid-gap: 10px;
}

    .client-selection-container .grid__2-4 {
        grid-column: 2 /span 3;
    }

    .client-selection-container button {
        font-size: 20px;
        padding: 3px;
    }

    .client-selection-container .grid-header {
        font-size: 24px;
        font-weight: 500;
    }

.table-header {
    text-align: center;
    font-size: 20px;
}

    .table-header .back-btn {
        margin-right: 5px;
        color: #6c757d;
        cursor: pointer;
    }

        .table-header .back-btn:hover {
            color: #5a6268;
        }

    .table-header .header-text {

    }

.donations-container .manage-btn-container {
    /*text-align: center;*/
}

    .donations-container .manage-btn-container .delete-btn-link {
        color: red;
        cursor: pointer;
        display: inline-block;
        margin: 0;
        padding-left: 15px;
    }

    .donations-container .manage-btn-container .edit-btn-link {
        color: green;
        cursor: pointer;
        display: inline-block;
        margin: 0;
        padding-left: 15px;
    }

    .donations-container .manage-btn-container .details-btn-link {
        color: blue;
        cursor: pointer;
        display: inline-block;
        margin: 0;
    }

        .donations-container .manage-btn-container .delete-btn-link:hover,
        .donations-container .manage-btn-container .details-btn-link:hover,
        .donations-container .manage-btn-container .edit-btn-link:hover {
            text-decoration: underline;
        }

.donations-container .popup-wrapper {
    position: fixed;
    z-index: 3;
    padding-top: 3%;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.4);
}

    .donations-container .popup-wrapper .popup-container {
        background-color: white;
        color: black;
        border-radius: 6px;
        padding: 8px;
        position: absolute;
        z-index: 3;
        bottom: 35%;
        left: 35%;
        width: 30%;
    }

        .donations-container .popup-wrapper .popup-container .popup-header {
        }

            .donations-container .popup-wrapper .popup-container .popup-header .popup-header-management {
                text-align: right;
            }
                .donations-container .popup-wrapper .popup-container .popup-header .popup-header-management .popup-close-btn {
                    font-size: 20px;
                    color: red;
                    cursor: pointer;
                }

                    .donations-container .popup-wrapper .popup-container .popup-header .popup-header-management .popup-close-btn:hover {
                        color: darkred;
                    }

        .donations-container .popup-wrapper .popup-container .popup-contents {
            display: grid;
            grid-template-columns: 1fr 5fr;
            grid-gap: 5px;
            margin: auto;
            padding: 0 15px;
        }

        .donations-container .popup-wrapper .popup-container .popup-contents label {
            font-weight: bold;
        }