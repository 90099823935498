/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.button_green {
    background-color: #70c17d;
    border-radius: 3px;
    border: 1px solid;
    color: white;
    padding: 5px;
    padding: 5px 15px;
}

.button_green:hover, .button_green:active {
    opacity: 0.5
}

.form_fields {
  max-width: 250px;
  width: 100%;
  margin: 0 auto;
}

.form_fields {
  min-width: 250px;
  width: 100%;
  margin: 0 auto;
}

.form-fields-login {
  display: block;
  min-width: 250px;
  width: 100%;
  margin: 0 auto;
}

.cell-wrap-text {
  white-space: normal !important;
}

.ag-layout-auto-height .ag-center-cols-clipper, .ag-layout-auto-height .ag-center-cols-container 
{
  min-height: 300px !important;
}