.image-module-container {

}

    .image-module-container #uploadImageBtn {
        text-align: center;
    }

    .image-module-container #imageContainer {
        width: 210px;
        height: 170px;
        padding: 10px;
        float: left;
        cursor: pointer;
    }

    .image-module-container #imageContainerTop {
        width: 200px;
        height: 150px;
        display: flex;
        align-items: center;
        justify-content: center
    }

    .image-module-container #imageContainerDescription {
        text-align: center;
        height: 30px;
    }
