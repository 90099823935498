.form-container {

}

    .form-container .dual-grid {
        display: grid;
        grid-template-columns: 1fr 5fr;
        grid-row-gap: 10px;
    }

        .form-container .dual-grid .dual-grid__1-2 {
            grid-column: span 2;
        }

        .form-container .dual-grid .field-by-field-container {
            grid-column: span 2;
            display: grid;
            grid-template-columns: 1fr 5fr 1fr 5fr;
            grid-row-gap: 10px;
            grid-column-gap: 10px;
        }

    .form-container .form-title {
        text-align: center;
        margin-bottom: 3%;
    }

    .form-container button,
    .form-container a.form-btn {
        background-color: #3c8dbc;
        border-color: #367fa9;
        border-radius: 3px;
        -webkit-box-shadow: none;
        box-shadow: none;
        border: 1px solid transparent;
        color: white;
        padding: 12px 6px;
        font-weight: normal;
        font-size: 16px;
        min-width: 50px;
    }

        .form-container button:hover,
        .form-container button:focus,
        .form-container a.form-btn:hover,
        .form-container a.form-btn:focus {
            background-color: #367fa9;
            border-color: #204d74;
            text-decoration: none;
            outline: none;
        }

        .form-container button:active,
        .form-container a.form-btn:active {
            background-color: #204d74;
            border-color: #122b40;
            box-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
        }

    .form-container .delete-btn {
        background-color: #dd4b39 !important;
        border-color: #da3a25 !important;
        color: white !important;
    }

        .form-container .delete-btn:hover,
        .form-container .delete-btn:focus {
            background-color: #da3a25 !important;
            border-color: #99291a !important;
        }

        .form-container .delete-btn:active {
            background-color: #99291a !important;
            border-color: #57170f !important;
        }

    .form-container .form-management-btns {
        text-align: center;
    }

        .form-container .form-management-btns :not(:first-child) {
            margin-left: 5px;
        }

        .form-container .form-management-btns button {
            padding: 11px 6px;
        }

        .form-container .form-management-btns a {
        }

    .form-container select,
    .form-container input {
        box-sizing: border-box;
        font-size: 16px;
        width: 100%;
        height: 50px;
        color: rgba(46, 46, 46, .8);
        border: 1px solid rgb(225, 225, 225);
        margin: 0 0 0 0;
        padding-left: 15px;
        padding-right: 15px;
        resize: none;
    }

    .form-container label {
        margin-top: 12px;
        text-align: left;
        padding-left: 0;
        font-weight: bold;
    }

    .form-container textarea {
        min-height: unset;
        height: unset;
        resize: vertical;
        border: 1px solid rgb(225, 225, 225);
        color: rgba(46, 46, 46, .8);
    }

    .form-container .info-icon {
        font-size: large;
        margin-top: 15px;
        margin-left: 5px;
    }

        .form-container .info-icon:hover {
            color: #80ba54;
        }